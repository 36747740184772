import React from 'react'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import renderBlok from '@renderBlok'
import { CssBaseline, Box } from '@material-ui/core'
import { PageContainer, ContentContainer } from '@system'

const useStyles = makeStyles((theme) => ({
  innerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  item: {
    padding: '21px',
    width: '33.33%',

    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))

const CardGalleryModule = (props) => {
  const { cards } = props.blok
  const classes = useStyles()

  return (
    <SbEditable content={props.blok}>
      <CssBaseline />
      <PageContainer>
        {!!cards && (
          <ContentContainer className={classes.innerContainer}>
            {cards.map((card) => (
              <Box key={card._uid} className={classes.item}>
                {renderBlok(card)}
              </Box>
            ))}
          </ContentContainer>
        )}
      </PageContainer>
    </SbEditable>
  )
}

export default CardGalleryModule
